import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    cardProjects: '.js-card-projects',
    splideCardProjects: '.js-card-projects-splide',
    prevButton: '.js-arrow-slider-previous-card-projects',
    nextButton: '.js-arrow-slider-next-card-projects',
  };

  const init = () => {

    const cardProjectsInstances = document.querySelectorAll(selectors.cardProjects);

    cardProjectsInstances.forEach((instance) => {
      const splideElement = instance.querySelector(selectors.splideCardProjects);
      const prevButton = instance.querySelector(selectors.prevButton);
      const nextButton = instance.querySelector(selectors.nextButton);


      if (splideElement instanceof HTMLElement) {
        const mainSplide = new Splide(splideElement, {
          type: 'loop',
          autoplay: false,
          drag: true,
          gap: 24,
          width: '100%',
          speed: 1000,
          perPage: 3,
          perMove: 1,
          breakpoints: {
            1400: {
              perPage: 3,
              drag: true
            },
            1024: {
              padding: {
                right: '12.5%',
              },
              perPage: 2,
              drag: true
            },
            640: {
              padding: {
                right: '12.5%',
              },
              perPage: 1,
              drag: true
            }
          },
          arrows: false,
          pagination: false,
        }).mount();

        if (prevButton instanceof HTMLElement) {
          prevButton.addEventListener('click', () => mainSplide.go('<'));
        }

        if (nextButton instanceof HTMLElement) {
          nextButton.addEventListener('click', () => mainSplide.go('>'));
        }

      }
    });
  };

  window.addEventListener('load', init);
})();
