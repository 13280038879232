(function () {
  'use strict';
  const init = () => {
    const axeptioLink = document.querySelector("a[href='#axeptio']");
    if (axeptioLink) {
      axeptioLink.addEventListener("click", function(event) {
        event.preventDefault();
        openAxeptioCookies();
      });
    }
  };

  window.addEventListener('load', init);
})();
