import Splide from '@splidejs/splide';
import { Fancybox } from '@fancyapps/ui';

(function () {
  'use strict';

  const selectors = {
    splide: '.js-gallery'
  };

  const init = () => {
    const splides = document.querySelectorAll(selectors.splide);
    splides.forEach((splide) => {
      if (splide instanceof HTMLElement) {
        new Splide(splide, {
          type: 'slide',
          rewind: true,
          autoplay: false,
          drag: true,
          width: '100%',
          speed: 1000,
          perPage: 2,
          padding: {
            right: '12.5%',
            left: '5%',
          },
          perMove: 1,
          breakpoints: {
            1024: {
              perPage: 2,
              drag: true
            },
            640: {
              perPage: 1,
              drag: true
            }
          },
          gap: 24,
          arrows: true,
          pagination: false,
          classes: {
            arrow: 'splide__arrow gallery-arrow',
            prev: 'splide__arrow--prev gallery-arrow-prev',
            next: 'splide__arrow--next gallery-arrow-next'
          }
        }).mount();
      }
    });

    Fancybox.bind('[data-fancybox="gallery"]', {});
  };

  window.addEventListener('load', init);
})();
