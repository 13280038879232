import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    cardNews: '.js-card-news',
    splideCardNews: '.js-card-news-splide',
    prevButton: '.js-arrow-slider-previous-card-news',
    nextButton: '.js-arrow-slider-next-card-news',
  };

  const init = () => {
    const cardNewsInstance = document.querySelectorAll(selectors.cardNews);


    cardNewsInstance.forEach((instance) => {
      const splideElement = instance.querySelector(selectors.splideCardNews);
      const prevButton = instance.querySelector(selectors.prevButton);
      const nextButton = instance.querySelector(selectors.nextButton);

      if (splideElement instanceof HTMLElement) {
        const mainSplide = new Splide(splideElement, {
          type: 'loop',
          autoplay: false,
          drag: true,
          gap: 24,
          width: '100%',
          speed: 1000,
          perPage: 4,
          perMove: 1,
          breakpoints: {
            1400: {
              perPage: 3,
              drag: true
            },
            1024: {
              padding: {
                right: '12.5%',
                left: '5%'
              },
              perPage: 2,
              drag: true
            },
            640: {
              padding: {
                right: '12.5%',
                left: '5%'
              },
              perPage: 1,
              drag: true
            }
          },
          arrows: false,
          pagination: false,
        }).mount();

        if (prevButton instanceof HTMLElement) {
          prevButton.addEventListener('click', () => mainSplide.go('<'));
        }

        if (nextButton instanceof HTMLElement) {
          nextButton.addEventListener('click', () => mainSplide.go('>'));
        }

      }
    });
  };

  window.addEventListener('load', init);
})();
