(function() {
  'use strict';

  const selectors = {
    nextButton: '.js-timeline-next',
    previousButton: '.js-timeline-previous',
    imageIndexActive: '[data-image-index="1"]',
    textIndexActive: '[data-text-index="1"]',
    dateIndexActive: '[data-date-index="1"]',
    imageIndex: '.js-timeline__image-single',
    dateIndex: '.js-timeline__date-single',
    textIndex: '.js-timeline__text-wrapper'
  };

  const updateButtonStates = (activeIndex, firstIndex, lastIndex, nextButton, previousButton) => {
    if (nextButton instanceof HTMLElement) {
      nextButton.disabled = activeIndex >= lastIndex;
    }
    if (previousButton instanceof HTMLElement) {
      previousButton.disabled = activeIndex <= firstIndex;
    }
  };

  const timelineInitialisation = () => {
    const nextButton = document.querySelector(selectors.nextButton);
    const previousButton = document.querySelector(selectors.previousButton);
    const imageIndex = document.querySelector(selectors.imageIndexActive);
    const textIndex = document.querySelector(selectors.textIndexActive);
    const images = document.querySelectorAll(selectors.imageIndex);
    const texts = document.querySelectorAll(selectors.textIndex);
    const dateActiveElement = document.querySelector(selectors.dateIndexActive);
    const dates = document.querySelectorAll(selectors.dateIndex);
    let firstIndex = '';
    let lastIndex = '';
    if (dates.length !== 0) {
      firstIndex = dates[0].dataset.dateIndex;
      lastIndex = dates.length;
    }
    let activeIndex = 1;

    for (const text of texts) {
      if (text instanceof HTMLElement) {
        text.classList.add('timeline__text-wrapper--hide');
      }
    }

    if (textIndex instanceof HTMLElement) {
      textIndex.classList.remove('timeline__text-wrapper--hide');
    }

    for (const image of images) {
      if (image instanceof HTMLElement) {
        image.classList.add('timeline__image-single--hide');
      }
    }

    if (imageIndex instanceof HTMLElement) {
      imageIndex.classList.remove('timeline__image-single--hide');
    }

    for (const date of dates) {
      if (date instanceof HTMLElement) {
        date.classList.add('timeline__date-single--hide');
      }
    }

    if (dateActiveElement instanceof HTMLElement) {
      dateActiveElement.classList.remove('timeline__date-single--hide');
    }

    const nextDateElement = document.querySelector(`[data-date-index="${activeIndex + 1}"]`);
    if (nextDateElement instanceof HTMLElement) {
      nextDateElement.classList.remove('timeline__date-single--hide');
    }

    const previousDateElement = document.querySelector(`[data-date-index="${activeIndex - 1}"]`);
    if (previousDateElement instanceof HTMLElement) {
      previousDateElement.classList.remove('timeline__date-single--hide');
    }

    if (dateActiveElement instanceof HTMLElement) {
      const nextDateElement = document.querySelector(`[data-date-index="${activeIndex + 3}"]`);
      if (nextDateElement instanceof HTMLElement) {
        nextDateElement.classList.remove('timeline__date-single--hide');
      }

      const nextThirdDateElement = document.querySelector(`[data-date-index="${activeIndex + 4}"]`);
      if (nextThirdDateElement instanceof HTMLElement) {
        nextThirdDateElement.classList.remove('timeline__date-single--hide');
      }

      const nextSecondDateElement = document.querySelector(`[data-date-index="${activeIndex + 2}"]`);
      if (nextDateElement instanceof HTMLElement) {
        nextSecondDateElement.classList.remove('timeline__date-single--hide');
      }
      dateActiveElement.classList.remove('timeline__date-single--hide');
      dateActiveElement.classList.add('timeline__date-single--active');
    }

    updateButtonStates(activeIndex, firstIndex, lastIndex, nextButton, previousButton);
  };

  const init = () => {
    const nextButton = document.querySelector(selectors.nextButton);
    const previousButton = document.querySelector(selectors.previousButton);
    const dateActiveElement = document.querySelector(selectors.dateIndexActive);
    const dates = document.querySelectorAll(selectors.dateIndex);
    let firstIndex = '';
    let lastIndex = '';
    if (dates.length !== 0) {
      firstIndex = dates[0].dataset.dateIndex;
      lastIndex = dates.length;
    }
    let activeIndex = 1;

    timelineInitialisation();

    if (nextButton instanceof HTMLElement) {
      nextButton.addEventListener('click', function() {
        const imageIndex = document.querySelector(`[data-image-index="${activeIndex}"]`);
        const textIndex = document.querySelector(`[data-text-index="${activeIndex}"]`);
        const dateIndex = document.querySelector(`[data-date-index="${activeIndex}"]`);

        activeIndex += 1;

        const newImageIndex = document.querySelector(`[data-image-index="${activeIndex}"]`);
        const newTextIndex = document.querySelector(`[data-text-index="${activeIndex}"]`);
        const newDateIndex = document.querySelector(`[data-date-index="${activeIndex}"]`);

        if (newDateIndex instanceof HTMLElement) {
          if (newImageIndex instanceof HTMLElement) {
            newImageIndex.classList.remove('timeline__image-single--hide');
          }

          if (newTextIndex instanceof HTMLElement) {
            newTextIndex.classList.remove('timeline__text-wrapper--hide');
          }

          if (newDateIndex instanceof HTMLElement) {
            newDateIndex.classList.add('timeline__date-single--active');
          }
          if (imageIndex instanceof HTMLElement) {
            imageIndex.classList.add('timeline__image-single--hide');
          }

          if (textIndex instanceof HTMLElement) {
            textIndex.classList.add('timeline__text-wrapper--hide');
          }

          if (dateIndex instanceof HTMLElement) {
            dateIndex.classList.remove('timeline__date-single--active');
          }

          if (newDateIndex.classList.contains('timeline__date-single--hide')) {
            newDateIndex.classList.remove('timeline__date-single--hide');

            const firstDateElement = document.querySelector(`[data-date-index="${activeIndex - 5}"]`);
            if (firstDateElement instanceof HTMLElement) {
              firstDateElement.classList.add('timeline__date-single--hide');
            }
          }
        } else {
          activeIndex -= 1;
        }

        updateButtonStates(activeIndex, firstIndex, lastIndex, nextButton, previousButton);
      });
    }

    if (previousButton instanceof HTMLElement) {
      previousButton.addEventListener('click', function() {
        const imageIndex = document.querySelector(`[data-image-index="${activeIndex}"]`);
        const textIndex = document.querySelector(`[data-text-index="${activeIndex}"]`);
        const dateIndex = document.querySelector(`[data-date-index="${activeIndex}"]`);

        activeIndex -= 1;

        const newImageIndex = document.querySelector(`[data-image-index="${activeIndex}"]`);
        const newTextIndex = document.querySelector(`[data-text-index="${activeIndex}"]`);
        const newDateIndex = document.querySelector(`[data-date-index="${activeIndex}"]`);

        if (newDateIndex instanceof HTMLElement) {
          if (newImageIndex instanceof HTMLElement) {
            newImageIndex.classList.remove('timeline__image-single--hide');
          }

          if (newTextIndex instanceof HTMLElement) {
            newTextIndex.classList.remove('timeline__text-wrapper--hide');
          }

          if (newDateIndex instanceof HTMLElement) {
            newDateIndex.classList.add('timeline__date-single--active');
          }
          if (imageIndex instanceof HTMLElement) {
            imageIndex.classList.add('timeline__image-single--hide');
          }

          if (textIndex instanceof HTMLElement) {
            textIndex.classList.add('timeline__text-wrapper--hide');
          }

          if (dateIndex instanceof HTMLElement) {
            dateIndex.classList.remove('timeline__date-single--active');
          }

          if (newDateIndex.classList.contains('timeline__date-single--hide')) {
            newDateIndex.classList.remove('timeline__date-single--hide');

            const firstDateElement = document.querySelector(`[data-date-index="${activeIndex + 5}"]`);
            if (firstDateElement instanceof HTMLElement) {
              firstDateElement.classList.add('timeline__date-single--hide');
            }
          }
        } else {
          activeIndex += 1;
        }

        updateButtonStates(activeIndex, firstIndex, lastIndex, nextButton, previousButton);
      });
    }

    for (const date of dates) {
      if (date instanceof HTMLElement) {
        date.addEventListener('click', (e) => {
          const imageIndex = document.querySelector(`[data-image-index="${activeIndex}"]`);
          const textIndex = document.querySelector(`[data-text-index="${activeIndex}"]`);
          const dateIndex = document.querySelector(`[data-date-index="${activeIndex}"]`);

          const oldIndex = activeIndex;
          activeIndex = e.currentTarget.dataset.dateIndex;

          const newImageIndex = document.querySelector(`[data-image-index="${activeIndex}"]`);
          const newTextIndex = document.querySelector(`[data-text-index="${activeIndex}"]`);
          const newDateIndex = document.querySelector(`[data-date-index="${activeIndex}"]`);

          if (newDateIndex instanceof HTMLElement) {
            if (newImageIndex instanceof HTMLElement) {
              newImageIndex.classList.remove('timeline__image-single--hide');
            }

            if (newTextIndex instanceof HTMLElement) {
              newTextIndex.classList.remove('timeline__text-wrapper--hide');
            }

            if (newDateIndex instanceof HTMLElement) {
              newDateIndex.classList.add('timeline__date-single--active');
            }
            if (imageIndex instanceof HTMLElement) {
              imageIndex.classList.add('timeline__image-single--hide');
            }

            if (textIndex instanceof HTMLElement) {
              textIndex.classList.add('timeline__text-wrapper--hide');
            }

            if (dateIndex instanceof HTMLElement) {
              dateIndex.classList.remove('timeline__date-single--active');
            }

            if (newDateIndex.classList.contains('timeline__date-single--hide')) {
              newDateIndex.classList.remove('timeline__date-single--hide');

              if (activeIndex > oldIndex) {
                const firstDateElement = document.querySelector(`[data-date-index="${activeIndex - 5}"]`);
                if (firstDateElement instanceof HTMLElement) {
                  firstDateElement.classList.add('timeline__date-single--hide');
                }
              } else {
                const firstDateElement = document.querySelector(`[data-date-index="${activeIndex + 5}"]`);
                if (firstDateElement instanceof HTMLElement) {
                  firstDateElement.classList.add('timeline__date-single--hide');
                }
              }
            }

            updateButtonStates(activeIndex, firstIndex, lastIndex, nextButton, previousButton);
          }
        });
      }
    }
  };
  window.addEventListener('load', init);
})();
