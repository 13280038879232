(function() {
  'use strict';

  const classes = {
    activeClass: 'quick-support__container__background--active'
  };

  const selectors = {
    supportButton: '.js-button-support',
    closeButton: '.js-close-support',
    supportContainer: '.js-support-container'
  };

  const init = () => {
    const supportButton = document.querySelector(selectors.supportButton);
    const closeButton = document.querySelector(selectors.closeButton);
    const supportContainer = document.querySelector(selectors.supportContainer);

    if (supportButton instanceof HTMLElement) {
      supportButton.addEventListener('click', function() {
        if (supportContainer instanceof HTMLElement) {
          supportContainer.classList.toggle(classes.activeClass);
        }

        toggleBodyCLass();
      });
    }

    if (closeButton instanceof HTMLElement) {
      closeButton.addEventListener('click', function() {
        if (supportContainer instanceof HTMLElement) {
          supportContainer.classList.remove(classes.activeClass);
        }

        toggleBodyCLass();
      });
    }

    function toggleBodyCLass() {
      if (supportContainer.classList.contains(classes.activeClass)) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  };

  window.addEventListener('load', init);
})();
