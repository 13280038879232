(function () {
  'use strict';

  const selectors = {
    article: '.',
    readData: '.',
  };

  const init = () => {
    /*
    const article = document.querySelectorAll(selectors.article)
    const readTimeDiv = document.querySelector(selectors.readData)

    const calculateReadTime = () => {
      let numOfWords = 0;
      const averageWPM = 250;
      article.forEach(article => {
        numOfWords += article.innerHTML.split(" ").length;
      });

      const readTime = numOfWords / averageWPM
      return readTimeDiv.innerHTML =  Math.ceil(readTime) + " " + "min";
    }
    calculateReadTime(); */
  };


  window.addEventListener('load', init);
})();
