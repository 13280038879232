(function () {
  'use strict';

  const selectors = {
    burger: '.js-burger-trigger',
    closeButton: '.close__nav__button',
    navigationLinks: '.js-navigation-link',
    checkboxSelector: 'checkbox_toggle',
    navigationScroll: '.js-header',
    navigationTop: '.js-navigation-top',
    stage: '.js-stage',
    navigationLayer: '.js-navigation-layer__logo-desktop',
    asideSectionTemplate: '.template-single__second-column-wrapper',
    singleUnderNavigation: '.template-single__first-navigation',
    firstItemActive: '.js-first-item-menu--active',
    singleIntro: '.template-single__first-intro'
  };

  const init = () => {
    const burger = document.querySelector(selectors.burger);
    const closeButton = document.querySelector(selectors.closeButton);
    const navigationScroll = document.querySelector(selectors.navigationScroll);
    const navigationTop = document.querySelector(selectors.navigationTop);
    const body = document.querySelector('body');
    const stage = document.querySelector(selectors.stage);
    const asideSectionTemplate = document.querySelector(selectors.asideSectionTemplate);

    window.addEventListener('scroll', () => {
      checkStage();
    });
    checkStage();

    function checkStage () {
      if (stage instanceof HTMLElement) {
        const stageHeight = stage.offsetHeight;
        const scrollTop = window.scrollY;

        if (scrollTop >= stageHeight - 170) {
          document.body.classList.add('scrolled-past-stage');
        } else {
          document.body.classList.remove('scrolled-past-stage');
        }
      }
    }

    function checkStageSinglePage () {
      if (stage instanceof HTMLElement) {
        const stageHeight = stage.offsetHeight;
        const scrollTop = window.scrollY;

        if (asideSectionTemplate instanceof HTMLElement) {
          if (!(scrollTop >= stageHeight + 240)) {
            asideSectionTemplate.style.transform = 'translateY(0)';
          }
        }
      }
    }

    if (navigationScroll instanceof HTMLElement && navigationTop instanceof HTMLElement) {
      let lastScrollTop = 0;
      let ticking = false;

      window.addEventListener('scroll', function () {
        if (window.innerWidth >= 1360) {
          if (!ticking) {
            window.requestAnimationFrame(function () {
              const st = window.pageYOffset || document.documentElement.scrollTop;

              if (st > lastScrollTop) {
                if (asideSectionTemplate instanceof HTMLElement && body.classList.contains('scrolled-past-stage')) {
                  asideSectionTemplate.style.transform = 'translateY(96px)';
                }
                checkStageSinglePage();
                navigationScroll.style.transform = `translateY(${navigationTop.offsetHeight * -1}px)`;
              } else {
                checkStage();
                if (asideSectionTemplate instanceof HTMLElement && body.classList.contains('scrolled-past-stage')) {
                  asideSectionTemplate.style.transform = `translateY(${140 + navigationTop.offsetHeight}px)`;
                }
                checkStageSinglePage();
                navigationScroll.style.transform = 'translateY(0)';
              }

              lastScrollTop = st <= 0 ? 0 : st;
              ticking = false;
            });

            ticking = true;
          }
        } else {
          navigationScroll.style.transform = 'translateY(0)';
        }
      });
    }

    if (burger instanceof HTMLElement) {
      burger.addEventListener('click', function () {
        const currentExpanded = burger.getAttribute('aria-expanded');

        currentExpanded === 'true' ? body.classList.remove('fixed') : body.classList.add('fixed');

        const newExpanded = currentExpanded === 'true' ? 'false' : 'true';

        burger.setAttribute('aria-expanded', newExpanded);
      });
    }

    if (closeButton instanceof HTMLElement) {
      closeButton.addEventListener('click', function () {
        const checkboxToggle = document.getElementById(selectors.checkboxSelector);

        checkboxToggle.checked = false;
      });
    }

    document.querySelector('.navigation__avoid__link').addEventListener('click', function (e) {
      const target = document.querySelector('#stage');
      if (target) {
        target.setAttribute('tabindex', '-1');
        target.focus();
      }
    });
  };

  window.addEventListener('load', init);
  window.addEventListener('resize', () => {
    const asideSectionTemplate = document.querySelector(selectors.asideSectionTemplate);

    if (window.innerWidth < 1360) {
      if (asideSectionTemplate instanceof HTMLElement) {
        asideSectionTemplate.style.transform = 'translateY(0)';
      }
    }
  });
})();
