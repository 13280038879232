(function () {
  'use strict';

  const selectors = {
    moderneInputFile: '.modern-title',
  };

  const TRANSLATIONS = {
    'fr-FR': 'Cliquez ou glissez un fichier dans cette zone pour le téléverser.',
    'de-DE': 'Klicken oder ziehen Sie eine Datei in diesen Bereich, um sie hochzuladen.',
    'en-US': 'Click or drag a file to this area to upload.'
  };

  const init = () => {
   const modernTitles = document.querySelectorAll(selectors.moderneInputFile);
    const htmlLang = document.documentElement.lang || 'en-US';

   modernTitles.forEach((modernTitle) => {
      if (modernTitle instanceof  HTMLElement) {
        modernTitle.textContent = TRANSLATIONS[htmlLang];
      }
   });
  };


  window.addEventListener('load', init);
})();
