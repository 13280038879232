(function() {
  'use strict';

  const selectors = {
    filterButton: '.js-filter-button-activator',
    filterButtonSend: '.js-filter__button',
    filterButtonReset: '.js-filter__button--reset',
    filterInputPast: '.js-filter-input',
    filterLabelPast: '.js-filter-label',
    searchNews: '.js-search-news',
    filterNews: '.js-filter-news',
    taxNews: '.js-tax-news',
    orderByBtn: '.js-invert-order-by',
    filterContainer: '.js-filter-container'
  };

  const init = () => {
    const filterButton = document.querySelector(selectors.filterButton);
    const filterButtonSend = document.querySelector(selectors.filterButtonSend);
    const filterButtonReset = document.querySelector(selectors.filterButtonReset);
    const orderByBtn = document.querySelector(selectors.orderByBtn);
    const filterContainer = document.querySelector(selectors.filterContainer);
    const searchNews = document.querySelector(selectors.searchNews);
    const filterNews = document.querySelector(selectors.filterNews);
    const taxNews = document.querySelector(selectors.taxNews);

    checkIfDataIsAlreadySet();

    function checkIfDataIsAlreadySet() {
      const urlParams = new URLSearchParams(window.location.search);

      const searchParam = urlParams.get('search') || ''; // Valeur du paramètre 'search', ou une chaîne vide par défaut
      const filterByParam = urlParams.get('filter_by') || ''; // Valeur du paramètre 'filter_by'
      const filterByDirParam = urlParams.get('filter_by_dir') || ''; // Valeur du paramètre 'filter_by_dir'
      const taxonomyParam = urlParams.get('taxonomy') || ''; // Valeur du paramètre 'taxonomy'

      // TODO : instanceof htlmelement
      if (searchNews && searchNews.value !== '') {
        searchNews.value = searchParam;
      }
      if (filterNews && filterNews.value !== '') {
        filterNews.value = filterByParam;
      }

      if (orderByBtn && orderByBtn.value !== '') {
        orderByBtn.value = filterByDirParam;
      }

      if (taxNews && taxNews.value !== '') {
        taxNews.value = taxonomyParam;
      }
    }

    if (filterButton instanceof HTMLElement) {
      filterButton.addEventListener('click', function() {
        const state = filterButton.dataset.state;
        const message = filterButton.dataset.message;
        const currentText = filterButton.textContent;

        state === 'close' ? filterContainer.classList.add('filter--active') : filterContainer.classList.remove('filter--active');
        state === 'close' ? filterButton.dataset.state = 'open' : filterButton.dataset.state = 'close';
        filterButton.textContent = message;
        filterButton.dataset.message = currentText;
      });
    }

    if (filterButtonSend instanceof HTMLElement) {
      filterButtonSend.addEventListener('click', (e) => {
        updateURLParams();
      });
    }

    if (orderByBtn instanceof HTMLElement) {
      orderByBtn.addEventListener('click', (e) => {
        const actual = orderByBtn.dataset.actual;
        orderByBtn.dataset.actual = orderByBtn.dataset.alt;
        orderByBtn.dataset.alt = actual;

        updateURLParams();
      });
    }

    if (filterButtonReset instanceof HTMLElement) {
      filterButtonReset.addEventListener('click', (e) => {
        clearSelection();
      });
    }

    function updateURLParams() {
      const updatedUrl = window.location.href;
      const url = new URL(updatedUrl.replace(/page\/\d+/i, ''));
      const params = new URLSearchParams(url.search);

      if (searchNews && searchNews.value !== '') {
        params.set('search', searchNews.value);
      } else {
        params.delete('search');
      }

      if (filterNews && filterNews.value !== '') {
        params.set('filter_by', filterNews.value);
      } else {
        params.delete('filter_by');
      }

      if (orderByBtn && orderByBtn.value !== '') {
        params.set('filter_by_dir', orderByBtn.dataset.actual);
      } else {
        params.delete('filter_by_dir');
      }

      if (taxNews && taxNews.value !== '') {
        params.set('taxonomy', taxNews.value);
      } else {
        params.delete('taxonomy');
      }

      const newUrl = `${url.pathname}?${params}`;
      window.history.replaceState({}, '', newUrl);

      window.location.href = newUrl;
    }

    function clearSelection() {
      updateURLParams();
    }

    if (filterButtonReset instanceof HTMLElement) {
      filterButtonReset.addEventListener('click', () => {
        clearSelection();

        const currentUrl = window.location.href.split('?')[0];
        window.location.assign(currentUrl);
      });
    }
  };

  window.addEventListener('load', init);
})();
