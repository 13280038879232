import Splide from '@splidejs/splide';
import { Fancybox } from '@fancyapps/ui';

(function () {
  'use strict';

  const selectors = {
    splideContainer: '.js-text-media-container',
    splideElement: '.js-text-media',
    splideArrowNext: '.js-arrow-slider-next-text-media',
    splideArrowPrevious: '.js-arrow-slider-previous-text-media'
  };

  const init = () => {
    const splides = document.querySelectorAll(selectors.splideContainer);

    splides.forEach((splide) => {
      if (splide instanceof HTMLElement) {
        const splideArrowNext = splide.querySelector(selectors.splideArrowNext);
        const splideElement = splide.querySelector(selectors.splideElement);
        const splideArrowPrevious = splide.querySelector(selectors.splideArrowPrevious);

        const currentSplide = new Splide(splideElement, {
          type: 'slide',
          rewind: true,
          // width: '100%',
          fixedWidth: '400px',
          fixedHeight: '400px',
          speed: 1000,
          perPage: 1,
          direction: splide.classList.contains('text-media--right') ? 'ltr' : 'rtl',
          padding: {
            right: splide.classList.contains('text-media--right') ? '12.5%' :'0',
            left: splide.classList.contains('text-media--left') ? '12.5%' :'0',
          },
          perMove: 1,
          breakpoints: {
            640: {
              padding: {
                right: splide.classList.contains('text-media--right') ? '12.5%' :'5%',
                left: splide.classList.contains('text-media--left') ? '12.5%' :'5%',
              },
              perPage: 1,
              drag: true
            },
            480: {
              direction: splide.classList.contains('text-media--right') || splide.classList.contains('text-media--left')? 'ltr' : 'rtl',
              fixedWidth: undefined,
              fixedHeight: undefined,
            }
          },
          gap: 24,
          arrows: false,
          pagination: false
        }).mount();

        const updateArrowState = () => {
          if (currentSplide.index === 0) {
            splideArrowPrevious?.classList.add('text-media__arrow--disabled');
            splideArrowPrevious?.setAttribute('disabled', 'true');
          } else {
            splideArrowPrevious?.classList.remove('text-media__arrow--disabled');
            splideArrowPrevious?.removeAttribute('disabled');
          }
        };

        currentSplide.on('mounted move', updateArrowState);

        if (splideArrowNext instanceof HTMLElement && splideArrowPrevious instanceof HTMLElement) {
          splideArrowNext.addEventListener('click', (e) => {
            currentSplide.go('+1');
          });

          splideArrowPrevious.addEventListener('click', (e) => {
            currentSplide.go('-1');
          });
        }

        updateArrowState();
      }
    });

    Fancybox.bind('[data-fancybox="text-media"]', {});
  };

  window.addEventListener('load', init);
})();
