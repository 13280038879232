(function () {
  'use strict';

  const selectors = {
    delayedVideo: '.js-delayed-video',
    playVideoButton: '.js-play-video-mobile',
    stopVideoButton: '.js-stop-video-mobile',
    coverVideo: '.js-video-cover'
  };

  const init = () => {
    const playVideoButton = document.querySelector(selectors.playVideoButton);
    const stopVideoButton = document.querySelector(selectors.stopVideoButton);

    const playVideo = () => {
      const delayedVideos = document.querySelectorAll(selectors.delayedVideo);
      const coverVideos = document.querySelectorAll(selectors.coverVideo);

      if (playVideoButton instanceof HTMLElement && playVideoButton instanceof HTMLElement) {
        playVideoButton.classList.add('stage__background-image-button--hidden');
        stopVideoButton.classList.remove('stage__background-image-button--hidden');

        delayedVideo();

        coverVideos.forEach(cover => {
          cover.remove();
        });

        delayedVideos.forEach(video => {
          const videoSrc = video.getAttribute('data-src');
          if (videoSrc) {
            video.setAttribute('src', videoSrc);
          }

          video.play();
        });
      }
    };

    const stopVideo = () => {
      const delayedVideos = document.querySelectorAll(selectors.delayedVideo);

      playVideoButton.classList.remove('stage__background-image-button--hidden');
      stopVideoButton.classList.add('stage__background-image-button--hidden');

      delayedVideos.forEach(video => {
        video.pause();
        video.currentTime = 0;
      });
    };

    if (playVideoButton instanceof HTMLElement) {
      playVideoButton.addEventListener('click', () => {
        playVideo();
      });
    }

    if (stopVideoButton instanceof HTMLElement) {
      stopVideoButton.addEventListener('click', () => {
        stopVideo();
      });
    }

    playVideo();
  };

  const delayedVideo = () => {
    const delayedVideos = document.querySelectorAll(selectors.delayedVideo);

    delayedVideos.forEach(video => {
      const videoSrc = video.getAttribute('data-src');

      if (videoSrc) {
        const sourceMP4 = document.createElement('source');
        sourceMP4.src = `${videoSrc}#t=0.1`;
        sourceMP4.type = 'video/mp4';
        video.appendChild(sourceMP4);
      }
    });
  };

  window.addEventListener('load', init);
})();
